<template>
    <h4 class="not-verified-message position-relative" v-if="isNotVerified && showCompanyNotVerifiedBlock">
        <a href="https://easy.jobs/docs/verify-your-company-profile/" class="link-help" target="_blank">How to
            verify?</a>
        {{ $t(`Your company is not verified, please verify your company.`) }}
        <router-link :to="{name: 'company.setting.verification'}"
                     class="control-button control-button--info has_bg_color ml-1">{{ $t('Details') }}
        </router-link>
    </h4>
</template>

<script>
import {mapState} from "vuex";

export default {
    data() {
        return {
            showCompanyNotVerifiedBlock: true,
        };
    },
    computed: {
        ...mapState(['company']),
        isNotVerified() {
            if (this.company) {
                return !this.company.is_verified;
            }
            return false;
        }
    },
    created() {
        this.showCompanyNotVerifiedBlock = _helper.getCookie('ej-test-mode') !== 'true';
    }
}
</script>

<style scoped>
.not-verified-message {
    padding: 15px;
    font-size: 14px;
    line-height: 1.5;
    background: #ff96351a;
    border-radius: 10px;
    color: #ff9635;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 30px 0;
}


.not-verified-message a.control-button {
    padding: 6px 12px;
}

a.link-help {
    top: 10px;
    position: absolute;
    right: 15px;
    font-size: 14px;
    color: #597dfc;
}

@media all and (max-width: 1400px) {
    .not-verified-message {
        margin: 20px 20px 0;
    }
}
@media (max-width: 991px) {
    .not-verified-message {
        /*margin: 90px 20px 0 !important;*/
        font-size: 12px;
        padding: 25px;
    }

    .not-verified-message .link-help {
        font-size: 10px;
    }
}

@media all and (max-width: 767px) {
    .not-verified-message {
        margin: 15px 15px 0;
    }
}
</style>
