<template>
    <transition>
        <div class="page-body">
            <!-- sidebar start -->
            <Sidebar/>
            <!-- sidebar end -->

            <main class="content-area" :class="{'no-scroll': hideReleaseNotification}">
                <!-- site header -->
                <Header/>

                <!-- dashboard body -->
                <subscription-cancel-block v-if="user.subscription_cancel_request" :date="user.package_validity"></subscription-cancel-block>
                <package-expired-block></package-expired-block>
                <company-not-verified-block></company-not-verified-block>
                <EmailVerificationBlock v-if="!isVerified"></EmailVerificationBlock>
                <PackageLimitExceededModal v-if="packageLimitExceeded" @closePackageLimitModal="packageLimitExceeded=false"></PackageLimitExceededModal>
                <slot v-else></slot>

                <Footer></Footer>
            </main>
        </div>
    </transition>
</template>
<script>
    import {mapState} from 'vuex';
    import CompanyNotVerifiedBlock from "../components/common/CompanyNotVerifiedBlock";
    import {EventBus} from "../extra/event-bus";
    import {CHECK_RELEASE_NOTIFICATION, PACKAGE_LIMIT_EXCEEDED} from "../constants/events";
    const EmailVerificationBlock = () => import("../components/auth/EmailVerificationBlock.vue");
    const SubscriptionCancelBlock = () => import("../components/common/SubscriptionCancelBlock.vue");
    const Header = () => import("../components/dashboard/Header.vue");
    const Footer = () => import('../components/common/DashboardFooter.vue');
    const Sidebar = () => import("../components/dashboard/Sidebar.vue");
    const PackageExpiredBlock = () => import('../components/common/PackageExpiredBlock.vue');
    const PackageLimitExceededModal = () => import('../components/modal/company/PackageLimitExceeded');
    export default {
        components: {
            CompanyNotVerifiedBlock,
            Footer,
            Header,
            Sidebar,
            EmailVerificationBlock,
            PackageExpiredBlock,
            SubscriptionCancelBlock,
            PackageLimitExceededModal
        },
        data() {
            return {
                hideReleaseNotification: false,
                isVerified: true,
                packageLimitExceeded: false,
            }
        },

        computed: {
            ...mapState(['user', 'company']),
        },

        mounted() {
            this.isVerified = this.user.email_verified_at;

            const el = document.getElementById('betterdocs-ia');
            if (_.isEmpty(this.company)) {
                el.style.display = "none";
            } else {
                el.style.display = "block";
            }
        },

        created() {
            EventBus.$on(CHECK_RELEASE_NOTIFICATION, (data) => {
                this.hideReleaseNotification = !data;
            });

            EventBus.$on(PACKAGE_LIMIT_EXCEEDED, () => {
                this.packageLimitExceeded = true;
            });
        },

    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../css/main.css"></style>
<style scoped>
    .content-area.no-scroll {
        overflow: hidden !important;
        height: 100vh !important;
    }


</style>
